.table-small-text {
    font-size: 0.6rem !important;
    width: 100%;
    word-wrap: break-word;
    table-layout: fixed;
    min-width: 5rem;
}

.table-small-medium-text {
    font-size: 0.8rem !important;
    width: 100%;
    word-wrap: break-word;
    table-layout: fixed;
    min-width: 7rem;
}

.table-small-text-filter {
    font-size: 10px !important;
}

/*disable label for filter textbox, placeholder is enough*/
.sr-only {
    display: none;
}