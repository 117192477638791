.sanger-qc-history-table {
    background: whitesmoke;
    cursor: pointer;
    word-wrap: break-word;
    display: block;
    table-layout: auto;
    overflow-x: scroll;
}

.sanger-qc-history-table-date {
    width: 12%;
    min-width: 6rem;
}

.sanger-qc-history-table-description {
    width: 30%;
    min-width: 12rem;
}

.sanger-qc-history-table-username {
    width: 16%;
    min-width: 6rem;
}

.sanger-qc-history-table-select-sl-numbers {
    width: 11%;
    min-width: 5.4rem;
}

.sanger-qc-history-table-select-stage {
    width: 17%;
    min-width: 8.7rem;
}