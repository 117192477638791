.toggle-small-text {
    font-size: 0.6rem !important;
    width: 100%;
    word-wrap: break-word;
    min-width: 7rem;
}

.toggle-small-title {
    font-size: 0.6rem !important;
    width: 100%;
    word-wrap: break-word;
}